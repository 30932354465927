import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { GET_CHAT_CHANNELS_QUERY_KEY } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { useMutation, type UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import {
  type CreateChatChannelRequest,
  createChatChannelRequestSchema,
  type CreateChatChannelResponse,
  createChatChannelResponseSchema,
} from "../types";

const CREATE_CHAT_CHANNEL_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/chat/channels`;

export function useCreateChatChannel(
  options: UseMutationOptions<CreateChatChannelResponse, unknown, CreateChatChannelRequest> = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CreateChatChannelRequest) => {
      const response = await post({
        url: CREATE_CHAT_CHANNEL_URL,
        requestSchema: createChatChannelRequestSchema,
        responseSchema: createChatChannelResponseSchema,
        data: params,
      });
      return response.data;
    },
    onSuccess: async () => {
      void queryClient.invalidateQueries(GET_CHAT_CHANNELS_QUERY_KEY);
    },
    ...options,
  });
}
