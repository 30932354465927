import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { z } from "zod";

import { type FacilityProfile, facilityProfileResponseSchema } from "../types";

function getFacilityProfileUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_BASE_API_URL}/facilityprofile/${workplaceId}`;
}

const facilityProfileRequestSchema = z.object({
  projection: z.string().optional(),
});

type FacilityProfileRequest = z.infer<typeof facilityProfileRequestSchema>;

type FacilityProfileParams = FacilityProfileRequest & {
  workplaceId: string;
};

export function useFacilityProfile(
  queryParams: FacilityProfileParams,
  options: UseGetQueryOptions<FacilityProfile> = {}
) {
  const { workplaceId, ...facilityProfileRequestParams } = queryParams;
  // allows for the query to be disabled by setting the enabled option to false
  // but still make sure that the query is not run if the workplaceId is not set
  const enabled = (options.enabled ?? true) && workplaceId !== "";

  return useGetQuery({
    url: getFacilityProfileUrl(workplaceId),
    queryParams: facilityProfileRequestParams,
    requestSchema: facilityProfileRequestSchema,
    responseSchema: facilityProfileResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_PROFILE_FAILURE,
    },
    ...options,
    enabled,
  });
}
