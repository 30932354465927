import { z } from "zod";

export const createChatChannelRequestSchema = z.object({
  facilityId: z.string(),
  agentId: z.string(),
});
export type CreateChatChannelRequest = z.infer<typeof createChatChannelRequestSchema>;

export const createChatChannelResponseSchema = z.string();
export type CreateChatChannelResponse = z.infer<typeof createChatChannelResponseSchema>;
